import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import "rxjs/add/operator/delay";

import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { Resgate } from "src/app/models/resgate.model";
import { Pontuacao } from "src/app/models/pontuacao.model";

@Injectable({
  providedIn: "root",
})
export class ResgateService {
  constructor(private http: HttpClient) {}

  byFilter(pagina: number, idPessoa: number): Observable<Resgate[]> {
    return this.http
      .post<Resgate[]>(`${environment.apiUrl}/Resgate/ByFilterPessoa`, {
        pagina,
        idPessoa,
      })
      .pipe(
        map((list) => {
          return list;
        })
      );
  }
  Resgate(pessoa: number, vantagem: number): Observable<any> {
    return this.http
      .post<any>(`${environment.apiUrl}/Resgate/Resgatar`, {
        pessoa,
        vantagem,
      })
      .pipe(
        map((retorno) => {
          return retorno;
        })
      );
  }
  getTrocaPontuacao(pagina: number, idCliente: number): Observable<Pontuacao[]> {
    return this.http
      .post<Pontuacao[]>(`${environment.apiUrl}/Resgate/getTrocaPontuacao`, {
        pagina,
        idCliente
      })
      .pipe(
        map((list) => {
          return list;
        })
      );
  }
  ResgatarCancelar(resgate: number, acao: string): Observable<any> {
    return this.http
      .post<any>(`${environment.apiUrl}/Resgate/ResgatarCancelar`, {
        resgate,
        acao
      })
      .pipe(
        map((retorno) => {
          return retorno;
        })
      );
  }
}
