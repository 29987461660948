import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import 'rxjs/add/operator/delay';

import { environment } from '../../../environments/environment';
import { Cliente } from 'src/app/models/cliente.model';
import { ServicoCredenciado } from 'src/app/models/servicoCredenciado.model';

@Injectable({
  providedIn: 'root',
})
export class ClienteService {
  constructor(private http: HttpClient) {}

  getCredenciado(pagina: number): Observable<Cliente[]> {
    return this.http
      .post<Cliente[]>(`${environment.apiUrl}/Cliente/GetCredenciados`, {
        pagina,
      })
      .pipe(
        map((list) => {
          return list;
        })
      );
  }

  getServicos(pagina: number, idPessoa: number): Observable<ServicoCredenciado[]> {
    return this.http
      .post<ServicoCredenciado[]>(`${environment.apiUrl}/Cliente/GetServicoCredenciado`, {
        pagina,
        idPessoa,
      })
      .pipe(
        map((list) => {
          return list;
        })
      );
  }
}
