import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import 'rxjs/add/operator/delay';

import { environment } from '../../../environments/environment';
import { Vantagem } from 'src/app/models/vantagem.model';

@Injectable({
  providedIn: 'root',
})
export class VantagemService {
  constructor(private http: HttpClient) {}

  byFilter(pagina: number): Observable<Vantagem[]> {
    return this.http
      .post<Vantagem[]>(`${environment.apiUrl}/Vantagem/ByFilter`, {
        pagina,
      })
      .pipe(
        map((list) => {
          return list;
        })
      );
  }

  byFilterPontos(pagina: number): Observable<Vantagem[]> {
    return this.http
      .post<Vantagem[]>(`${environment.apiUrl}/Vantagem/ByFilterPontos`, {
        pagina,
      })
      .pipe(
        map((list) => {
          return list;
        })
      );
  }

  byId(id: number): Observable<Vantagem> {
    return this.http
      .post<Vantagem>(`${environment.apiUrl}/Vantagem/ById`, {
        id,
      })
      .pipe(
        map((retorno) => {
          return retorno;
        })
      );
  }

  consultar(cpf: string) {
    return this.http.post<any[]>(`${environment.apiUrl}/med/consultar`, {
      cpf,
    });
  }

  consultarPlano(cpf: string) {
    return this.http.post<any[]>(`${environment.apiUrl}/med/consultarPlano`, {
      cpf,
    });
  }

  consultarCartao(cartao: string) {
    return this.http.post<any[]>(`${environment.apiUrl}/med/consultarCartao`, {
      cartao,
    });
  }

  consultarTag(cpf: string, parceiro: number) {
    return this.http.post<any[]>(`${environment.apiUrl}/med/consultarTag`, {
      cpf,
      parceiro
    });
  }

  consultarCartaoTag(cartao: string, parceiro: number) {
    return this.http.post<any[]>(`${environment.apiUrl}/med/consultarCartaoTag`, {
      cartao,
      parceiro
    });
  }

  receber(model: any) {
    return this.http.post<any[]>(`${environment.apiUrl}/med/receberTag`, {
      cliente: +model.cliente,
      cpf: model.cpf,
      valorPago: model.valorPago,
      observacao: model.observacao,
      senha: model.senha,
    });
  }

  receberCartao(model: any) {
    return this.http.post<any[]>(`${environment.apiUrl}/med/receberCartaoTag`, {
      cliente: +model.cliente,
      cartao: model.numeroCartaoFisico,
      valorPago: model.valorPago,
      observacao: model.observacao,
      senha: model.senha
    });
  }
  
  receberSaude(model: any) {
    return this.http.post<any[]>(`${environment.apiUrl}/med/receberSaude`, {
      cliente: +model.cliente,
      identificacao: model.identificacao,
      valorPago: model.valorPago,
      descricao: model.descricao,
      tipoAtendimento: model.tipoAtendimento,
      tipoIdentificacao: model.tipoIdentificacao,
    }).pipe(
      map((retorno) => {
        return retorno;
      })
    );
  }
}
