import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import 'rxjs/add/operator/delay';

import { environment } from '../../../environments/environment';
import { Checkin } from 'src/app/models/checkin.model';
import { map } from 'rxjs/operators';

export interface FinancialMovementResultInterface {
  cpf: string;
  data: Date;
  dataCompensacao: Date;
  hora: Date;
  id: number;
  nome: string;
  recebidoPor: string;
  valor: number;
  valorCompensacao: number;
  situacao: string;
  carteira: string;
}

export interface ReciboResultInterface {
  cnpj: string;
  cpfRemetente: string;
  creditos: number;
  favorecido: string;
  idTransacao: number;
  remetente: string;
  data: Date;
  hora: Date;
}

@Injectable({
  providedIn: 'root',
})
export class FinanceiroService {
  constructor(private http: HttpClient) {}

  getFinancialMovement(credenciadoId: number): Observable<FinancialMovementResultInterface[]> {
    return this.http.get<FinancialMovementResultInterface[]>(
      `${environment.apiUrl}/financeiro/ultimastransacoes/?clienteCredenciadoId=${credenciadoId}`
    );
  }

  getVoucherMovement(voucherId: number): Observable<ReciboResultInterface> {
    return this.http.get<ReciboResultInterface>(
      `${environment.apiUrl}/financeiro/recibo-por-transacao/?transacaoId=${voucherId}`
    );
  }
  
  getCheckin(pagina: number, idCliente: number): Observable<Checkin[]> {
    return this.http
      .post<Checkin[]>(`${environment.apiUrl}/financeiro/getCheckin`, {
        pagina,
        idCliente
      })
      .pipe(
        map((list) => {
          return list;
        })
      );
  }

}
